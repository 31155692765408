import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import  HepServiceApi from "../../../api/hep/hep-screen-api";
import { ISearchHEPResponse } from "../../../api/hep/hep-screen-model";


export interface ISearchHEPSlice {
  searchResult: ISearchHEPResponse | null;
  loading: boolean;
  error: any;
  status: string;
}

const INITIAL_STATE = {
  searchResult: null,
  loading: false,
  error: null,
  status: "idle",
} as ISearchHEPSlice;

export const storeSearchHEPData = createAsyncThunk(
  "hep/searchHEPData",
  HepServiceApi.searchHEPData
);

const searchHEPSlice = createSlice({
  name: "searchHEPData",
  initialState: INITIAL_STATE,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(storeSearchHEPData.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(storeSearchHEPData.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.searchResult = action.payload.data;
        state.status = "succeeded";
      })
      .addCase(storeSearchHEPData.rejected, (state, action) => {
        state.loading = false;
        state.searchResult = null;
        state.error = action.payload;
        state.status = "failed";
      });
  },
});

export default searchHEPSlice;
