import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hepScreenApi from "../../../api/hep/hep-screen-api";
import { API_RESPONSE_STATUS } from "../../../constants/common.constant";
import { IGetRequestHepResponse } from "../../../api/hep/hep-screen-model";

export interface IGetHepRequestDataModel {
  response: IGetRequestHepResponse;
  loading: boolean;
  error: any;
  status: string;
}

const INITIAL_STATE = {
  response: {},
  loading: false,
  error: null,
  status: "idle"
} as IGetHepRequestDataModel;

export const _getHepRequestData = createAsyncThunk(
  "hep/getHepRequestData",
  hepScreenApi.getRequests
);

export const getHepRequestDataSlice = createSlice({
  name: "hepRequestedData",
  initialState: INITIAL_STATE,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(_getHepRequestData.pending, (state) => {
        state.loading = true;
        state.status = API_RESPONSE_STATUS.LOADING;
      })
      .addCase(_getHepRequestData.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.response = action.payload.data;
        state.status = API_RESPONSE_STATUS.SUCCESS;
      })
      .addCase(_getHepRequestData.rejected, (state, action) => {
        state.loading = false;
        state.response = {} as IGetRequestHepResponse;
        state.error = action.payload;
        state.status = API_RESPONSE_STATUS.FAILED;
      })
  },
});

export default getHepRequestDataSlice;