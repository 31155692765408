import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
    
        loading: false,
        currentStatus: "",
        currentActivityStage: ""
    
} as {

        loading: boolean;
        currentStatus: string;
        currentActivityStage: string;
    
}


export const hepScreenStateSlice = createSlice({
    name: "hepScreenState",
    initialState: INITIAL_STATE,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setStatus: (state, action) => {
            state.currentStatus = action.payload
        }
    },
});

// Action creators are generated for each case reducer function
export const { setLoading, setStatus } = hepScreenStateSlice.actions;

export default hepScreenStateSlice.reducer;
