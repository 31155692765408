import { createSlice } from "@reduxjs/toolkit";
import { IFileUploadData } from "../../../api/hep/hep-screen-model";

const INITIAL_STATE = {
    data: {
        fileUploaded: {
            fileName: "",
            fileSize: 0,
            form: null,
        }
    } as IFileUploadData
}


export const fileUploadedContextSlice = createSlice({
    name: "fileUploadedContext",
    initialState: INITIAL_STATE,
    reducers: {
        setFileUploadedContext: (state, action) => {
            state.data = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setFileUploadedContext } = fileUploadedContextSlice.actions;

export default fileUploadedContextSlice.reducer;
