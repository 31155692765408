import { combineReducers } from "@reduxjs/toolkit";
import createNewRequestHEPSlice from "./createNewRequestHEPSlice";
import requestsDataSlice from "./getRequestsSlice";
import activityDetailsSlice from "./getActivityDetailsSlice";
import sharedHepContextSliceReducer from "./sharedHepContextSlice";
import fileUploadedContextReducer from "./fileUploadedContextSlice";
import getHepRequestDataReducer from "./getHepRequestedDataSlice";
import hepScreenStateReducer from "./globalScreenState";
import searchHEPReducer from "./searchHEPSlice";

export const hepRooteReducer = combineReducers({
    createNewRequestHEP: createNewRequestHEPSlice.reducer,
    getRequests: requestsDataSlice.reducer,
    globalContextData: sharedHepContextSliceReducer,
    fileUploadedContext: fileUploadedContextReducer,
    retrieveHepRequestedData: getHepRequestDataReducer.reducer,
    globalScreenState: hepScreenStateReducer,
    searchHEPesult: searchHEPReducer.reducer,
    getActivityDetails: activityDetailsSlice.reducer,
});