import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HepServiceApi from "../../../api/hep/hep-screen-api";
import { IActivityDetailsModel } from "../../../api/hep/hep-screen-model";


export interface IRequestsSlice {
    activityData: IActivityDetailsModel | null;
    loading: boolean;
    error: any;
    status: string;
}

const INITIAL_STATE = {
    activityData: null,
    loading: false,
    error: null,
    status: "idle",
} as IRequestsSlice;

export const getActivityDetailsContext = createAsyncThunk(
    "hep/getActivityDetailsContext",
    HepServiceApi.getActivityDetails
);

export const _resetActivityDetails = createAction('/hep/resetActivityDetails');

const requestsDataSlice = createSlice({
    name: "getActivityDetailsContext",
    initialState: INITIAL_STATE,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getActivityDetailsContext.pending, (state) => {
                state.loading = true;
                state.status = "loading";
            })
            .addCase(getActivityDetailsContext.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.activityData = action.payload.data[0];
                state.status = "succeeded";
            })
            .addCase(getActivityDetailsContext.rejected, (state, action) => {
                state.loading = false;
                state.activityData = null;
                state.error = action.payload;
                state.status = "failed";
            })
            .addCase(_resetActivityDetails, (state) => {
                state.activityData = null;
                state.loading = false;
                state.error = null;
                state.status = "idle";
            });
    },
});

export default requestsDataSlice;
