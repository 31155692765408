
import httpClient from "../../common/http/httpClient";
import { AxiosResponse } from "axios";
import { IContractorFilteredRequest, IContractorFilteredResponse, IContractorInformationRequest, IHEPService, IActivityPTWRequest, IRequestsRequest, ICommonHEPModel, IGetRequestHepResponse, IRemoveRecordRequest, ISearchHEPResponse, ISearchHEPRequest, IGetWorkerRequest, IGetWorkerResponse, IGetActivityDetailsRequest, IActivityDetailsModel } from "./hep-screen-model";
import { IDefaultResponse } from "../common/common.model";

const HepServiceApi = (): IHEPService => {
    return {
        getContractorFiltered: (request: IContractorFilteredRequest): HttpPromise<IContractorFilteredResponse> => {
            return httpClient
                .post<IContractorFilteredResponse>(`${process.env.REACT_APP_API_BASE_URL}/api/hep/requests/filtered-data`, { data: request })
                .then((res: AxiosResponse<IContractorFilteredResponse, any>) => res);
        },
        contractorInformationRequest: (request: IContractorInformationRequest): HttpPromise<any> => {
            return httpClient
                .post<IContractorInformationRequest>(`${process.env.REACT_APP_API_BASE_URL}/api/hep/requests/contractor-information-request`, { data: request })
                .then((res: AxiosResponse<IContractorInformationRequest, any>) => res);
        },
        postActivityDetails: (request: ICommonHEPModel): HttpPromise<IDefaultResponse> => {
            return httpClient
                .post<IDefaultResponse>(`${process.env.REACT_APP_API_BASE_URL}/api/hep/activity-details`, { data: request })
                .then((res: AxiosResponse<IDefaultResponse, any>) => res);
        },
        saveActivityPTW: (request: IActivityPTWRequest): HttpPromise<any> => {
            return httpClient
                .post<any>(`${process.env.REACT_APP_API_BASE_URL}/api/hep/activity/ptw`, { data: request })
                .then((res: AxiosResponse<any, any>) => res);
        },
        getRequests: (request: IRequestsRequest): HttpPromise<IGetRequestHepResponse> => {
            return httpClient
                .post<IGetRequestHepResponse>(`${process.env.REACT_APP_API_BASE_URL}/api/hep/requests/request`, {data: request})
                .then((res: AxiosResponse<IGetRequestHepResponse, any>) => res);
        },
        uploadWorkerFile: (request: any): HttpPromise<any> => {
            return httpClient
                .post<any>(`${process.env.REACT_APP_API_BASE_URL}/api/hep/activities/${request.activityId}/workers`, { data: request.data, headers: {
                    'content-type': 'multipart/form-data',
                } })
                .then((res: AxiosResponse<any, any>) => res);
        },
        postActivitySafety: (request: ICommonHEPModel): HttpPromise<IDefaultResponse> => {
            return httpClient
                .post<IDefaultResponse>(`${process.env.REACT_APP_API_BASE_URL}/api/hep/activity/safety-team`, { data: request })
                .then((res: AxiosResponse<IDefaultResponse, any>) => res);
        },
        saveActivityWorker: (request: ICommonHEPModel): HttpPromise<IDefaultResponse> => {
            return httpClient
                .post<IDefaultResponse>(`${process.env.REACT_APP_API_BASE_URL}/api/hep/activity/worker`, { data: request })
                .then((res: AxiosResponse<IDefaultResponse, any>) => res);
        },
        removeRecordActivity: (request: IRemoveRecordRequest): HttpPromise<IDefaultResponse> => {
            return httpClient
                .post<IDefaultResponse>(`${process.env.REACT_APP_API_BASE_URL}/api/hep/activity-details/remove-record`, { data: request })
                .then((res: AxiosResponse<IDefaultResponse, any>) => res);
        },
        searchHEPData: (request: ISearchHEPRequest): HttpPromise<ISearchHEPResponse> => {
            return httpClient
                .post<ISearchHEPResponse>(`${process.env.REACT_APP_API_BASE_URL}/api/hep/search`, { data: request })
                .then((res: AxiosResponse<ISearchHEPResponse, any>) => res);
        },
        getWorkerData: (request: IGetWorkerRequest): HttpPromise<IGetWorkerResponse> => {
            return httpClient
                .post<IGetWorkerResponse>(`${process.env.REACT_APP_API_BASE_URL}/api/hep/worker-data`, { data: request })
                .then((res: AxiosResponse<IGetWorkerResponse, any>) => res);
        },
        updateRequestStatus: (request: IRequestsRequest): HttpPromise<IRequestsRequest> => {
            return httpClient
                .post<IDefaultResponse>(`${process.env.REACT_APP_API_BASE_URL}/api/hep/requests/status`, { data: request })
                .then((res: AxiosResponse<IDefaultResponse, any>) => res);
        },
        getActivityDetails: (request: IGetActivityDetailsRequest): HttpPromise<IGetActivityDetailsRequest> => {
            return httpClient
                .post<IActivityDetailsModel[]>(`${process.env.REACT_APP_API_BASE_URL}/api/hep/activities/activity-details`, { data: request })
                .then((res: AxiosResponse<IActivityDetailsModel[], any>) => res);
        }
    };
};

export default HepServiceApi();
